<template>
  <div :class="modalClasses">
    <div class="modal-mask" @click="$emit('close')" />

    <div class="modal-wrapper" :style="`min-width: min(${width}px, calc(100% - 20px))`">
      <div class="modal-container">
        <div class="modal-header">
          <slot name="header" />
          <button type="button" class="modal-close-button" @click="$emit('close')">
            <img class="icon" src="/icons/x.svg" />
          </button>
        </div>

        <div class="modal-body">
          <slot />
        </div>

        <div class="modal-footer">
          <slot name="footer" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    wide: {
      type: Boolean,
      default: false,
      required: false,
    },
    width: {
      type: Number,
      default: 600,
      required: false,
    },
  },

  computed: {
    modalClasses() {
      return {
        modal: true,
        wide: this.wide,
      }
    },
  },

  mounted() {
    // Add event listener for esc key
    document.addEventListener('keydown', this.handleEscKey)
  },
  beforeDestroy() {
    document.removeEventListener('keydown', this.handleEscKey)
  },
  methods: {
    handleEscKey(event) {
      if (event.key === 'Escape') {
        this.$emit('close')
      }
    },
  },
}
</script>

<style lang="scss">
.modal {
  display: contents;
}

.modal-mask {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 11;
  background: rgba(0, 0, 0, 0.6);
}

.modal-wrapper {
  display: block;
  max-height: 95%;
  overflow-y: auto;
  position: fixed;
  z-index: 12;
  border-radius: 4px;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  background: white;
  box-shadow: 0 0 60px 10px rgba(0, 0, 0, 0.9);
}

.modal.wide .modal-wrapper {
  width: 90vw;
  max-width: 1600px;
}

.modal-header {
  & .modal-close-button {
    position: absolute;
    top: 15px;
    right: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 8px;
    border: none;
    border-radius: 20px;
    background-color: transparent;

    &:hover {
      cursor: pointer;
      background-color: #f0f0f0;
    }

    & img {
      width: 24px;
    }
  }
}

.modal-container {
  width: 100%;
  padding: 24px 24px 40px 24px;
}
</style>
