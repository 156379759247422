<script>
export default {
  data() {
    return {
      isModalOpen: false,
    }
  },

  methods: {
    onModalOpen() {
      this.isModalOpen = true
    },

    onModalClose() {
      this.isModalOpen = false
    },
  },
}
</script>
