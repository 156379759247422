<template>
  <div>
    <ClipLoader v-if="!text" class="spinner" :size="size" :color="color" />
    <VPopover v-if="text" trigger="hover" placement="top">
      <ClipLoader class="spinner" :size="size" :color="color" />
      <template slot="popover">
        <main>{{ text }}</main>
      </template>
    </VPopover>
  </div>
</template>

<script>
import { VPopover } from 'v-tooltip'

import ClipLoader from 'vue-spinner/src/ClipLoader.vue'

export default {
  components: {
    VPopover,
    ClipLoader,
  },

  props: {
    text: {
      type: String,
    },
  },

  data() {
    return {
      color: '#ffb973',
      size: '1rem',
    }
  },
}
</script>

<style>
.spinner {
  display: inline;
  position: relative;
  top: 0.2rem;
}
</style>
